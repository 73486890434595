<template>
  <svg viewBox="0 0 25 24">
    <path
      d="M19.5 20.9999H5.5C5.23478 20.9999 4.98043 20.8946 4.79289 20.707C4.60536 20.5195 4.5 20.2651 4.5 19.9999V10.9999H1.5L11.827 1.61192C12.0111 1.4444 12.2511 1.35156 12.5 1.35156C12.7489 1.35156 12.9889 1.4444 13.173 1.61192L23.5 10.9999H20.5V19.9999C20.5 20.2651 20.3946 20.5195 20.2071 20.707C20.0196 20.8946 19.7652 20.9999 19.5 20.9999ZM6.5 18.9999H18.5V9.15692L12.5 3.70292L6.5 9.15692V18.9999ZM8.5 14.9999H16.5V16.9999H8.5V14.9999Z"
    />
  </svg>
</template>

<script>
export default {
  name: "HomeIcon",
};
</script>
